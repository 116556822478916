<template>
  <div class="main-page">
    <div class="main-page__top">
      <PaymentTitleControllerSkeleton />
      <ClientOnly>
        <SecretShopMiniOffersController v-if="changeMD(true, false)" />
      </ClientOnly>
    </div>
    <div class="main-page__content">
      <PaymentPromoField v-if="changeMD(false, true)" />
      <PaymentRightSideControllerSkeleton />
      <PaymentLeftSideControllerSkeleton />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCountriesStore } from '~/features/payment/store/countries.store';
import { useBonusesStore } from '~/features/payment/store/bonuses.store';
import { usePromoStore } from '~/features/payment/store/promo.store';
import { useOffersStore } from '~/features/secret-shop/store/offers.store';
import { useCountryStore } from '~/features/payment/store/country.store';

import auth from '~/features/payment/middleware/auth';

import { SAVED_PROMO_KEY } from '~/features/payment/constants/keys.constants';
import { useInfoStore } from '~/features/promocode-popup/store/info.store';

definePageMeta({
  layout: 'payment',
  middleware: auth,
});

const changeMD = GlobalUtils.Media.changeByMedia('md');

const countriesStore = useCountriesStore();
const bonusesStore = useBonusesStore();

const promoStore = usePromoStore();
const { promoInfo } = storeToRefs(promoStore);

const promocodeInfoStore = useInfoStore();
const { parsedPromo } = storeToRefs(promocodeInfoStore);

const countryStore = useCountryStore();

const offersStore = useOffersStore();
const { isLoaded: offersLoaded, selectedOffer } = storeToRefs(offersStore);

const savedPromo = usePersistedRef(SAVED_PROMO_KEY, '');

const { queryValue, clearOfferIdQuery } = useUTM();

if (parsedPromo.value) {
  promoInfo.value.input = parsedPromo.value;
  promoStore.handlePromoInput(promoInfo.value.input);
}

await useAsyncData(
  'payment-base-data',
  () => {
    const promises = [countriesStore.getCountries(), bonusesStore.getBonuses(), countryStore.getCountry('default')];

    if (!offersLoaded.value) {
      promises.push(offersStore.getOffers());
    }

    return Promise.allSettled(promises);
  },
  {
    server: false,
    lazy: true,
  },
);

onMounted(() => {
  if (!savedPromo.value || parsedPromo.value) return;
  promoInfo.value.input = savedPromo.value;
});

watch(
  offersLoaded,
  (value) => {
    if (!value) return;
    if (queryValue.value.offerId) {
      selectedOffer.value = offersStore.findOfferById(+queryValue.value.offerId);
      clearOfferIdQuery();
    } else {
      selectedOffer.value = null;
    }
  },
  {
    immediate: true,
  },
);

watch(
  promoInfo,
  (value) => {
    savedPromo.value = !value.input || !value.isSucceed ? '' : value.input;
  },
  {
    deep: true,
  },
);
</script>

<style src="./MainPage.scss" scoped lang="scss"></style>
